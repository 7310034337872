import * as types from "../actionTypes";
import Client from 'shopify-buy';
import { SHOPIFY_STOREFRONT_ACCESS_TOKEN, SHOPIFY_DOMAIN } from '../../config';

const client = Client.buildClient({
    storefrontAccessToken: SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    domain: SHOPIFY_DOMAIN
}, fetch);

//add to cart
export const addToCart = (handle, partnerType, addToast) => {
    return (dispatch) => {
        client.product.fetchByHandle(handle).then((product) => {
            addToast("Course Added to cart", {
                appearance: "success",
                autoDismiss: true
            });
            dispatch({ type: types.ADD_TO_CART, payload: { handle: product.handle, partnerType: partnerType, variantId: product.variants[0].id } });
        });
    }
}

//remove from cart
export const removeFromCart = (handleToRemove, addToast) => {
    return dispatch => {
        if (addToast) {
            addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
        }
        dispatch({ type: types.REMOVE_FROM_CART, payload: handleToRemove });
    };
};

//change course seats quantity from cart
export const changeQty = (handle, changeByNumber) => {
    return dispatch => {
        dispatch({ type: types.CHANGE_QTY, payload: { handle, changeByNumber } });
    };
};

//remove all from cart
export const removeAllFromCart = () => {
    return dispatch => {
        // if (addToast) {
        //     addToast("Removed All From Cart", {
        //         appearance: "error",
        //         autoDismiss: true
        //     });
        // }
        dispatch({ type: types.REMOVE_ALL_FROM_CART });
    };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
    if (item.stock) {
        return item.stock;
    } else {
        return item.variation
            .filter(single => single.color === color)[0]
            .size.filter(single => single.name === size)[0].stock;
    }
};

export const fetchCourseByHandle = (handle, addToast) => {
    return (dispatch) => {
        client.product.fetchByHandle(handle).then((product) => {
            dispatch(addToCart(product.variants[0].id, product.title, handle, product.variants[0].price, addToast))
        });
    }
}
