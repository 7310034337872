import * as types from '../actionTypes'

const initialState = {
    filterType: "",
    selectedCollectionID: "",
    selectedJrsID: "",
    selectedFunctionalRoleID: ""
};

function courseFilterReducer(state = initialState, action) {
    switch (action.type) {
        case types.FILTER_COURSE:
            if (action.payload.filterType === "collection") {
                return { ...state, filterType: action.payload.filterType, selectedCollectionID: action.payload.filterTypeID, selectedJrsID: "", selectedFunctionalRoleID: "" }
            }

            if (action.payload.filterType === "jurisdiction") {
                return { ...state, filterType: action.payload.filterType, selectedJrsID: action.payload.filterTypeID }
            }

            if (action.payload.filterType === "functional-role") {
                return { ...state, filterType: action.payload.filterType, selectedFunctionalRoleID: action.payload.filterTypeID }
            }
            break
        default:
            return state
    }
}


export default courseFilterReducer