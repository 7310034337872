import * as types from "../actionTypes"
import axios from "axios";
import APIConstants from "../../utils/APIConstants";
import cryptojs from "crypto-js"; 

export const authRequest = () => {
    return {
        type: types.AUTH_REQUEST
    }
}

export const authSuccess = (user) => {
    return {
        type: types.AUTH_SUCCESS,
        user: user,
        isLoggedIn : true
    }
}

export const authFailure = () => {
    return {
        type: types.AUTH_FAILURE
    }
}

export const fetchUser = (values) => {
    return (dispatch) => {
        dispatch(authRequest())
        let postData = {"email":values.email,"password":values.password}
        axios.post(APIConstants.API_DESTINATION + APIConstants.LOGIN_USER,postData)
            .then(res => {
              console.debug("res",res)
                if(res.data.status == "success"){
                  const user = res.data.data[0];
                  user.password = encrypt(values.password);
                  dispatch(authSuccess(user))
                }else{
                  dispatch(authFailure())
                }
            })
            .catch(err => {
                dispatch(authFailure())
            })
    }
}

const encrypt = (password) => {
    var iv = cryptojs.enc.Utf8.parse(APIConstants.CRYPTO_IV);
    var key = cryptojs.enc.Utf8.parse(APIConstants.CRYPTO_KEY);
    let enc_password = cryptojs.AES.encrypt(password,key,
                                     {iv: iv, mode: cryptojs.mode.CBC, padding: cryptojs.pad.Pkcs7}).toString();
    return enc_password;
}

export const logout = () => {
    return {
        type: types.LOGOUT
    }
}

