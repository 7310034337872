import * as types from '../actionTypes'
import functionalRoles from '../../data/functionalRole.json';

const getFuncRolesObj = () => {
    let formatterArr = [];
    functionalRoles.forEach(item => {
        formatterArr.push({
            id: item.id,
            name: item.name,
            selected: true
        })
    })

    return formatterArr
}


const initialState = {
    listView: false,
    cardView: true,
    showImage: true,
    allFuncRolesDisplayed: true,
    subscriptionCoursesDisplayed: true,
    allFunctionalRoles: getFuncRolesObj(),
    displayedFunctionalRolesId: [],
    isMemberDisplayed: false
}

function filterViewReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_LIST_VIEW:
            return { ...state, cardView: false, listView: true }
        case types.SET_CARD_VIEW:
            return { ...state, cardView: true, listView: false }
        case types.TOGGLE_VIEW_IMAGE:
            return { ...state, showImage: !state.showImage }
        case types.TOGGLE_FUNC_ROLE_SELECTION:
            let indexOfFuncRoleObj = state.allFunctionalRoles.findIndex(item => item.id === action.payload);
            let newAllFunctionalRoles = [...state.allFunctionalRoles];
            newAllFunctionalRoles[indexOfFuncRoleObj] = { ...newAllFunctionalRoles[indexOfFuncRoleObj], "selected": !newAllFunctionalRoles[indexOfFuncRoleObj].selected }

            if (newAllFunctionalRoles[indexOfFuncRoleObj].selected)
                return { ...state, allFunctionalRoles: newAllFunctionalRoles }
            else
                return { ...state, allFunctionalRoles: newAllFunctionalRoles, "allFuncRolesDisplayed": newAllFunctionalRoles[indexOfFuncRoleObj].selected }

        case types.TOGGLE_All_FUNC_ROLE_SELECTION:
            let newAllFuncRoles = state.allFunctionalRoles.map(item => {
                return { ...item, "selected": !state.allFuncRolesDisplayed }
            })
            return { ...state, allFuncRolesDisplayed: !state.allFuncRolesDisplayed, allFunctionalRoles: newAllFuncRoles }

        case types.TOGGLE_SUBSCRIPTION_COURSE_DISPLAY:
            return { ...state, subscriptionCoursesDisplayed: !state.subscriptionCoursesDisplayed }

        case types.TOGGLE_IS_MEMBER_COURSES:
            return { ...state, isMemberDisplayed: !state.isMemberDisplayed }

        case types.SET_DISPLAYED_FUNC_ROLES:
            return { ...state, displayedFunctionalRolesId: [...action.payload] }
        default:
            return state
    }
}





export default filterViewReducer