import * as types from "../actionTypes"
import axios from "axios";
import APIConstants from "../../utils/APIConstants";
 


export const subscribedCourseRequest = () => {
    return {
        type: types.SUBSCRIBEDCRS_REQUEST
    }
}

export const subscribedCourseSuccess = (subscribedCrsList) => {
    return {
        type: types.SUBSCRIBEDCRS_SUCCESS,
        subscribedCrs: subscribedCrsList
    }
}

export const subscribedCourseFailure = () => {
    return {
        type: types.SUBSCRIBEDCRS_FAILURE
    }
}

export const getSubscribedCourseDetails = (email)=>{
    return(dispatch)=>{
        dispatch(subscribedCourseRequest())
        axios.get(APIConstants.API_DESTINATION+APIConstants.SUBSCIBEDCRSURL+'?email='+email)
        .then(res=>{
            if(res.data.status == "success"){
               dispatch(subscribedCourseSuccess(res.data.data));
            }else{
                dispatch(subscribedCourseFailure());
            }
        })
        .catch(err => {
            dispatch(subscribedCourseFailure())
        })

    }
}
//subscribe a course subscribeCourse

export const subscribeCourse = (handle, partnerType,email)=>{
    return(dispatch)=>{
        let handleObj = handle.split('||')
        let subOption = {
            email:email, 
            charge_interval_frequency:30 , 
            order_interval_frequency:30 , 
            order_interval_unit:'month' , 
            product_id:Number(handleObj[1]) , 
            quantity:1, 
            discount_code:'',
            variant_id :Number(handleObj[0])
        }
        axios.post(APIConstants.API_DESTINATION+APIConstants.RECHARGE_PRE_SUBSCRIPTION,subOption)
        .then(res=>{
            let apires = (res.data)
            if(apires.status == "success"){
                if(apires.data.checkout.token)    {
                    window.location.href = APIConstants.RECHARGE_SUB_URL+apires.data.checkout.token+"?myshopify_domain="+
                    APIConstants.SHOPIFY_NAME+"&email="+apires.data.checkout.email;
                }
            }else{

            }
        })
        .catch(err => {

        })

    }
}

export const clearSubscriptionState = () =>{
    return {
        type : types.CLEARSUBSCRIBEDCRSSTATE
    }
}
 