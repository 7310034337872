import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
// import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Logout from "./pages/auth/Logout";
import { useSelector } from "react-redux";

require('dotenv').config()

const Home = lazy(() => import("./pages/shop/Home"));
const AllCourses = lazy(() => import("./pages/shop/AllCourses"));
const Courses = lazy(() => import("./pages/shop/Courses"));

// shop pages
const SearchResult = lazy(() => import("./pages/shop/SearchResult"));

// product pages
const ProfessionalCourseDetail = lazy(() => import("./pages/shop/ProfessionalCourseDetail"));
const PractitionerCourseDetail = lazy(() => import("./pages/shop/PractitionerCourseDetail"));
const PractitionerBundleCourseDetail = lazy(() => import("./pages/shop/PractitionerBundleCourseDetail"));

// other pages
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LogIn = lazy(() => import("./pages/auth/LogIn"));
const SignUp = lazy(() => import("./pages/auth/SignUp"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));


const OurPartners = lazy(() => import("./pages/other/OurPartners"));
const Policy = lazy(() => import("./pages/other/PrivacyPolicy"));
const Terms = lazy(() => import("./pages/other/TermsCondition"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const CustomerSupport = lazy(() => import("./pages/other/CustomerSupport"));
const ContactUs = lazy(() => import("./pages/other/ContactUs"));


const Cart = lazy(() => import("./pages/other/Cart"));


/** partners */
const PartnerLanding = lazy(() => import("./pages/partners/PartnerLanding"));

const NotFound = lazy(() => import("./pages/other/NotFound"));



const App = props => {

  const partnerTypeState = useSelector(state => state.partnerTypeState)


  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                {partnerTypeState.partnerType === "shop" || partnerTypeState.partnerType === "shop-ce" ?
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + "/"}
                    component={Home}
                  />
                  : <Route
                    exact
                    path={process.env.PUBLIC_URL + "/"}
                    component={PartnerLanding}
                  />
                }
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses"}
                  component={Courses}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/courses/professional/:param"}
                  component={ProfessionalCourseDetail}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses/practitioner/:param"}
                  component={PractitionerCourseDetail}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/courses/practitioner-bundle/:rechargeHandle"}
                  component={PractitionerBundleCourseDetail}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/our-partners"}
                  component={OurPartners}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/terms-condition"}
                  component={Terms}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/privacy-policy"}
                  component={Policy}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/faq"}
                  component={FAQ}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/customer-support"}
                  component={CustomerSupport}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/contact-us"}
                  component={ContactUs}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/all-courses"}
                  component={AllCourses}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/search/:searchParam"}
                  component={SearchResult}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/login"}
                  component={LogIn}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/sign-up"}
                  component={SignUp}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/forgot-password"}
                  component={ForgotPassword}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/reset-password"}
                  component={ResetPassword}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/logout"}
                  component={Logout}
                />
                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

// export default connect()(multilanguage(App));
export default App;

