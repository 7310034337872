import * as types from '../actionTypes'

const initialState = {
    partnerType: 'shop'
}


function partnerTypeReducer(state = initialState, action) {

    switch (action.type) {
        case types.SET_PARTNER_TYPE:
            return { partnerType: action.payload }
        default:
            return state
    }
}

export default partnerTypeReducer