import { combineReducers } from 'redux';

import filterViewReducer from './filterViewReducer';
import fetchCoursesReducer from './fetchCoursesReducer';
import fetchCourseDetailReducer from './fetchCourseDetailReducer';

import cartReducers from './cartReducers';
import productReducer from './productReducer';
import courseFilterReducer from './courseFilterReducer';
import authReducer from './authReducer';
import signUpReducer from './signUpReducer';
import partnerTypeReducer from './partnerTypeReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import resetPasswordReducer from './resetPasswordReducer';
import userProfileReducer from './userProfileReducer';
import myCourseListingReducer from './myCourseListingReducer';
import subscribedCoursesReducer from './subscribedCoursesReducer';
import purchasedHistoryReducer from './purchasedHistoryReducer';
import userCourseSeatReducer from './userCourseSeatReducer';

const rootReducer = combineReducers({
    filterViewState: filterViewReducer,
    coursesState: fetchCoursesReducer,
    courseDetailState: fetchCourseDetailReducer,
    cartState: cartReducers,
    courseFilterState: courseFilterReducer,
    authState: authReducer,
    userState: signUpReducer,
    resetPasswordState: resetPasswordReducer,
    forgotPasswordState: forgotPasswordReducer,
    partnerTypeState: partnerTypeReducer,
    userProfileState: userProfileReducer,
    myCoursesState: myCourseListingReducer,
    subscribedCrsState: subscribedCoursesReducer,
    purchasedHistoryState: purchasedHistoryReducer,
    userCrsMapState: userCourseSeatReducer
});

export default rootReducer;