import * as types from '../actionTypes'

const initialState = []

function cartReducers(state = initialState, action) {

    const removeCourseFromCart = (handleToRemove) => {
        return state.filter(courseObj => courseObj.handle != handleToRemove)
    }

    switch (action.type) {
        case types.ADD_TO_CART:
            let indexOfHandle = state.findIndex(item => item.handle === action.payload.handle)
            // if item already exists in cart
            if (indexOfHandle != -1)
                return state

            return [...state, { ...action.payload, quantity: 1 }]

        case types.REMOVE_FROM_CART:
            return removeCourseFromCart(action.payload)

        case types.CHANGE_QTY:

            let filteredArr = state.filter(item => item.handle == action.payload.handle)
            let currentQuantity;

            if (filteredArr[0])
                currentQuantity = state.filter(item => item.handle == action.payload.handle)[0].quantity;

            if (currentQuantity == 1 && action.payload.changeByNumber == -1) {
                return removeCourseFromCart(action.payload.handle)
            } else {
                return state.map(item =>
                    item.handle === action.payload.handle
                        ? { ...item, quantity: item.quantity + action.payload.changeByNumber }
                        : item
                );
            }

        case types.REMOVE_ALL_FROM_CART:
            return []
        default:
            return state
    }
}

export default cartReducers