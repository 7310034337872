import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import partners from './data/partners.json';

import store from "./redux/store";
import * as fetchCoursesActions from './redux/actions/fetchCoursesActions';
import * as partnerTypeActions from './redux/actions/partnerTypeActions';
import * as filterViewActions from './redux/actions/filterViewActions';



let directory = window.location.pathname;

const checkIfPartnerExists = urlPathFirstElement => {
  let filteredObj = partners.filter(item => item.name === urlPathFirstElement)
  return filteredObj.length > 0
}


// if (directory.split('/')[1] && checkIfPartnerExists(directory.split('/')[1])) {
//   store.dispatch(partnerTypeActions.setPartnerType(window.location.pathname.split('/')[1]));
//   store.dispatch(fetchCoursesActions.fetchShopifyCourses(window.location.pathname.split('/')[1]));
// } else {
//   store.dispatch(fetchCoursesActions.fetchShopifyCourses())
// }



// 0 - shop, 1 - egadd, 2 - techuk, 3 - ieit, 4 - schar, 5 - shop-ce
const partnerType = "schar";
store.dispatch(partnerTypeActions.setPartnerType(partnerType));
store.dispatch(fetchCoursesActions.fetchShopifyCourses(partnerType));

// store.dispatch(fetchCoursesActions.fetchRechargeCourses(partnerType));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
