import * as types from '../actionTypes'

export const setListView = () => {
    return {
        type: types.SET_LIST_VIEW
    }
}

export const setCardView = () => {
    return {
        type: types.SET_CARD_VIEW
    }
}

export const toggleViewImage = () => {
    return {
        type: types.TOGGLE_VIEW_IMAGE
    }
}

export const toggleFuncRoleSelection = funcRoleid => {
    return {
        type: types.TOGGLE_FUNC_ROLE_SELECTION,
        payload: funcRoleid
    }
}

export const toggleMemberNonMemberCourses = funcRoleid => {
    return {
        type: types.TOGGLE_IS_MEMBER_COURSES
    }
}



export const toggleAllFuncRoleSelection = () => {
    return {
        type: types.TOGGLE_All_FUNC_ROLE_SELECTION
    }
}

export const toggleSubscriptionCourses = () => {
    console.log('1')
    return {
        type: types.TOGGLE_SUBSCRIPTION_COURSE_DISPLAY
    }
}

export const setDisplayedFuncRoles = funcRoleIds => {
    return {
        type: types.SET_DISPLAYED_FUNC_ROLES,
        payload: funcRoleIds
    }
}